.leftControl,
.rightControl {
  @apply absolute z-20;
  top: 40%;
  outline: none;
  transition: background-color 0.2s ease;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: white;
}

.leftControl:hover,
.rightControl:hover,
.leftControl:focus,
.rightControl:focus {
  @apply outline-none;
}

.leftControl {
  @apply left-4 bg-cover;
}

.rightControl {
  @apply right-4 bg-cover;
}
